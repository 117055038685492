
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
function LinkPreview(props) {

    const { data } = props

    const [linkId, setLinkId] = useState('')
    const [isValid, setIsValid] = useState(false)
    useEffect(() => {


        var location = window.location.pathname.split('/')[1]

        if (data.isValid === true) {


            setLinkId(location)
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `.custom-bg { background-color: ${data.tenantInfo.bgColor} !important; }`;
            document.getElementsByTagName('head')[0].appendChild(style);

            var btnStyle = document.createElement('style');
            btnStyle.type = 'text/css';
            btnStyle.innerHTML = `.custom-btn { background-color: ${data.tenantInfo.brandColor} !important; border-color:  ${data.tenantInfo.brandColor} !important;}`;
            document.getElementsByTagName('head')[0].appendChild(btnStyle);


            var txtStyle = document.createElement('style');
            txtStyle.type = 'text/css';
            txtStyle.innerHTML = `.custom-txt { color: ${data.tenantInfo.brandColor} !important;}`;
            document.getElementsByTagName('head')[0].appendChild(txtStyle);
            setIsValid(true)
        } else {
            setIsValid(false)
        }

    }, [])

    return (
        <Container className='vh-100 custom-bg' fluid>
            <Row className='h-100'>
                <Col xs={6} className='bg-white text-center mx-auto my-auto rounded p-3'>

                    {isValid && <>
                        {data.tenantInfo.hasLogo && <img src={`https://phalanx-tenant-photos.s3.amazonaws.com/${data.tenantInfo.tenantId}`} alt={data.tenantInfo.name} className='img-fluid animate__animated animate__fadeIn' />}
                        {data.tenantInfo.displayName && data.tenantInfo.displayName != "" &&
                            <h4 className='my-3'>A Member of <strong className='custom-txt'>{data.tenantInfo.displayName}</strong> is Sharing a File With You</h4>
                        }
                        {(!data.tenantInfo.displayName || data.tenantInfo.displayName =="") &&
                            <h4 className='my-3'>A Member of <strong className='custom-txt'>{data.tenantInfo.name}</strong> is Sharing a File With You</h4>
                        }
                        <Row>
                            <Col md={2} className='mx-auto'>
                                {!data.tenantInfo.showLinkPreview &&
                                    <img src={require('./assets/file-icon.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && data.name.split('.').pop() == 'pdf' &&
                                    <img src={require('./assets/pdf_phlx.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && (data.name.split('.').pop() == 'doc' || data.name.split('.').pop() == 'docx') &&
                                    <img src={require('./assets/doc_phlx.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && (data.name.split('.').pop() == 'ppt' || data.name.split('.').pop() == 'pptx') &&
                                    <img src={require('./assets/ppt_phlx.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && (data.name.split('.').pop() == 'xls' || data.name.split('.').pop() == 'xlsm' || data.name.split('.').pop() == 'xlsx') &&
                                    <img src={require('./assets/xls_phlx.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && (data.name.split('.').pop() == 'txt' || data.name.split('.').pop() == 'rtf') &&
                                    <img src={require('./assets/txt_phlx.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                                {data && data.tenantInfo && data.tenantInfo.showLinkPreview && (data.name.split('.').pop() != 'txt' && data.name.split('.').pop() != 'rtf' &&
                                    data.name.split('.').pop() != 'pdf' && data.name.split('.').pop() != 'docx' && data.name.split('.').pop() != 'doc' && data.name.split('.').pop() != 'ppt' &&
                                    data.name.split('.').pop() != 'pptx' && data.name.split('.').pop() != 'xls' && data.name.split('.').pop() != 'xlsx' && data.name.split('.').pop() != 'xlsm') &&
                                    <img src={require('./assets/file-icon.png')} className='img-fluid' alt='phalanx file icon' />
                                }
                            </Col>
                        </Row>
                        {data && data.tenantInfo && data.tenantInfo.showLinkPreview && <p className='text-center mb-3'><strong>{data.name}</strong></p>}


                        <a href={`https://app.phalanx.io/#/linkview/${linkId}`} className='btn custom-btn mt-4'>Access File</a>


                    </>}

                    {!isValid && <>
                        <img alt="phalanx-logo" className='img-fluid' src={require('./assets/phalanx_icon.png')} />
                        <h4 className='my-3'>This Link IS Not Valid</h4></>}

                    <Row>
                        <Col md={5} className='text-end ms-auto'>
                            <span className='float-left'>Powered By <a href="https://phalanx.io">Phalanx</a> </span> <img width={20} height={20} className='float-right' src={require('./assets/phalanx_icon.png')} />
                        </Col>
                    </Row>

                </Col>

            </Row>
        </Container>
    );
}

export default LinkPreview;
