import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import LinkPreview from './LinkPreview';
import axios from 'axios'
function App() {


  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    _getInfo()
  }, [])

  const _getInfo = async () => {
    var location = window.location.pathname.split('/')[1]
    if(!location){
      window.location.href = 'https://phalanx.io'
      return
    }

    try {

      const res = await axios.get(`https://api.phalanx.io/transfer/externaldatashare/status/${location}/null`, { 'Content-Type': 'application/json', "authorization": 'blah blah' })
      console.log(res.data)
      setData(res.data)
      setLoading(false)
    } catch (e) {
      console.log(e)

      setData({isValid : false})
      setLoading(false)

    }
  }



  return (
    <div className="App">
      {loading && <Container className='vh-100 bg-purple ' fluid>
        <Row className='h-100'>
          <Col xs={2} className='text-center mx-auto my-auto '>
            <img src={require('./assets/phalanx_icon.png')} alt="phalanx-icon" className='img-fluid animate__animated animate__fadeIn' />
            <br />
            <Spinner animation='border' variant='white' />

          </Col>

        </Row>
      </Container>}
      {!loading && data && <LinkPreview data={data} />}
    </div>
  );
}

export default App;
